import React, {  useEffect } from 'react'

import { Grid, Container } from '@mui/material'
import {
  WppListItem,
  WppSelect,
  WppLabel,
  WppInput
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import {
  selectedRegionDispatcher,
  selectedMarketDispatcher,
  selectedAgencyDispatcher,
  getRegListDispatcher,
  getMarketListDispatcher,
  getAgencyListDispatcher,
  selectedInvDispatcher,
  selectedTypeOfServiceDispatcher,
  getMarketList,
  getAgencyList,
  getServiceTypeListDispatcher
} from './action'
import { Step1Props } from './interface'
import styles from './Step1.module.scss'
import { USER_ROLE } from '../../../../../helper/constants'

const mapStateToProps = (state: any) => {
  return {
    selectedRegion: state.caSelectedRegReducer.data,
    selectedMarket: state.caSelectedMarketReducer.data,
    selectedAgency: state.caSelectedAgencyReducer.data,
    regList: state.caRegListReducer.data,
    marketList: state.caMarketListReducer.data,
    agencyList: state.caAgencyListReducer.data,
    isLaunchAssess: state.launchAssessRed.data,
    rowDetails: state.inventoryRowDetailsRed.data,
    userContext: state.getOrgByUserRed.context,
    userData: state.getOrgByUserRed.data,
    selectedTypeOfService: state.caTypeOfServiceReducer.data,
    serviceTypeList: state.caServiceTypeListReducer.data,
    vendorDetails: state.vendorDetails.vendor
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  selectedInvDispatcher: (inv: { id: number; name: string }) => dispatch(selectedInvDispatcher(inv)),
  selectedRegionDispatcher: (reg: { id: number; name: string }) => dispatch(selectedRegionDispatcher(reg)),
  selectedMarketDispatcher: (market: { id: number; name: string }) => dispatch(selectedMarketDispatcher(market)),
  selectedAgencyDispatcher: (agency: { id: number; name: string }) => dispatch(selectedAgencyDispatcher(agency)),
  getRegListDispatcher: (regList: any, header: object) => dispatch(getRegListDispatcher(regList, header)),
  getMarketListDispatcher: (marketList: any, header: object) => dispatch(getMarketListDispatcher(marketList, header)),
  getAgencyListDispatcher: (userId: number, marketId: number, isAdmin: boolean, header: object) =>
    dispatch(getAgencyListDispatcher(userId, marketId, isAdmin, header)),
  getServiceTypeListDispatcher: (header: object) => dispatch(getServiceTypeListDispatcher(header)),
  selectedTypeOfServiceDispatcher: (typeOfService: { id: number; serviceType: string }) =>
    dispatch(selectedTypeOfServiceDispatcher(typeOfService)),
  getMarketList: (marketList: any) => dispatch(getMarketList(marketList)),
  getAgencyList: (agencyList: any) => dispatch(getAgencyList(agencyList))
})

const Step1 = ({
  selectedInvDispatcher,
  getServiceTypeListDispatcher,
  serviceTypeList,
  setValidationErrors,
  selectedRegionDispatcher,
  selectedMarketDispatcher,
  selectedAgencyDispatcher,
  selectedMarket,
  selectedAgency,
  getMarketList,
  getAgencyList,
  selectedTypeOfService,
  validationErrors,
  regList,
  getMarketListDispatcher,
  marketList,
  agencyList,
  getAgencyListDispatcher,
  isLaunchAssess,
  rowDetails,
  userContext,
  userData,
  selectedTypeOfServiceDispatcher,
  selectedRegion,
  getRegListDispatcher,
  vendorDetails
}: Step1Props) => {
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const isSiteAdmin = userData?.groups.some((group: any) => group?.name === USER_ROLE.SAD)
  useEffect(() => {
    if (isLaunchAssess && isLaunchAssess.openAssess) {
      selectedInvDispatcher &&
        selectedInvDispatcher({
          id: rowDetails.id,
          name: rowDetails.name,
          market: rowDetails.market,
          region: rowDetails.region,
          agency: rowDetails.agency
        })
    }
  }, [])

  useEffect(() => {
    getRegListDispatcher && getRegListDispatcher(regList, headers)
    getServiceTypeListDispatcher && getServiceTypeListDispatcher(headers)
  }, [])

  useEffect(() => {
    if (selectedRegion?.name) {
      getMarketListDispatcher && getMarketListDispatcher(selectedRegion?.id, headers)
    }
  }, [selectedRegion])

  useEffect(() => {
    if (selectedMarket?.name) {
      getAgencyListDispatcher?.(userContext?.id, selectedMarket?.id, isSiteAdmin, headers)
    }
  }, [selectedMarket])

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: any) => item.name !== fieldName)
  }

  const handleRegion = (e: any) => {
    console.log('inner call')
    selectedRegionDispatcher && selectedRegionDispatcher(e.target.value)
    getMarketList?.('')
    getAgencyList?.('')
    setValidationErrors?.(updateValidationList('regionId'))
  }

  const handleMarket = (e: any) => {
    selectedMarketDispatcher && selectedMarketDispatcher(e.target.value)
    getAgencyList?.('')
    setValidationErrors && setValidationErrors(updateValidationList('marketId'))
  }

  const handleAgency = (e: any) => {
    selectedAgencyDispatcher && selectedAgencyDispatcher(e.target.value)
    setValidationErrors && setValidationErrors(updateValidationList('agencyId'))
  }

  const handleTypeOfService = (e: any) => {
    selectedTypeOfServiceDispatcher?.(e.target.value)
    setValidationErrors?.(updateValidationList('serviceTypeId'))
  }
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={5}>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel
              className={styles.label}
              config={{ text: 'Vendor Name' }}
              htmlFor="v-name"
              typography="s-strong"
            />
            <WppInput id="v-name" size="s" disabled value={vendorDetails?.name} data-testid="step1-vendor-name" />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel className={styles.label} config={{ text: 'Country' }} htmlFor="v-country" typography="s-strong" />
            <WppInput id="v-country" size="s" disabled value={vendorDetails?.country?.name} data-testid="step1-country-name"/>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel
              className={styles.label}
              config={{ text: 'Industry' }}
              htmlFor="v-industry"
              typography="s-strong"
            />
            <WppInput id="v-industry" size="s" disabled value={vendorDetails?.industry?.name} data-testid="step1-industry-name"/>
          </Grid>
          <Grid item xs={12} sm={4} md={4} paddingTop="5rem !important">
            <WppLabel className={styles.vLabel} config={{ text: 'Region' }} htmlFor="v-region" typography="s-strong" />
            <WppSelect
              id="v-region"
              onWppChange={handleRegion}
              placeholder="Select Region"
              value={selectedRegion}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === 'regionId')?.error) || ''
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === 'regionId')?.error
                  ? 'error'
                  : undefined
              }
              data-testid="step1-region"
            >
              {regList?.length ? (
                regList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item?.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4} paddingTop="5rem !important">
            <WppLabel className={styles.vLabel} config={{ text: 'Market' }} htmlFor="v-market" typography="s-strong" />
            <WppSelect
              id="v-market"
              onWppChange={handleMarket}
              placeholder="Select Market"
              disabled={selectedRegion?.name === ''}
              value={selectedMarket}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === 'marketId')?.error) || ''
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === 'marketId')?.error
                  ? 'error'
                  : undefined
              }
            >
              {marketList?.length ? (
                marketList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4} paddingTop="5rem !important">
            <WppLabel className={styles.vLabel} config={{ text: 'Agency' }} htmlFor="v-agency" typography="s-strong" />
            <WppSelect
              id="v-agency"
              onWppChange={e => handleAgency(e)}
              placeholder="Select Agency"
              disabled={selectedMarket?.name === ''}
              value={selectedAgency}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === 'agencyId')?.error) || ''
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === 'agencyId')?.error
                  ? 'error'
                  : undefined
              }
            >
              {agencyList?.length ? (
                agencyList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4} paddingTop="5rem !important">
            <WppLabel
              className={styles.vLabel}
              config={{ text: 'Type of Service' }}
              htmlFor="v-type-service"
              typography="s-strong"
            />
            <WppSelect
              id="v-type-service"
              onWppChange={handleTypeOfService}
              placeholder="Select Service"
              value={selectedTypeOfService}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === 'serviceTypeId')?.error) || ''
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === 'serviceTypeId')?.error
                  ? 'error'
                  : undefined
              }
            >
              {serviceTypeList?.length ? (
                serviceTypeList?.map((item: { serviceType: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.serviceType}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1)
