import React, { useLayoutEffect, useState } from 'react'

import { Typography } from '@mui/material'
import {
  WppDivider,
  WppIconChannel,
  WppIconDatabase,
  WppIconDataViewList,
  WppIconLink,
  WppIconLockOn,
  WppIconNote,
  WppNavSidebar,
  WppNavSidebarItem,
  WppIconDataHistogram
} from '@wppopen/components-library-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './SideNavWpp.module.scss'
import appConfig from '../../app.config'
import { USER_ROLE, PROD, NPROD, DATA_MAPPING } from '../../helper/constants'

import '../../assets/scss/App.scss'

const mapStateToProps = (state: any) => {
  return {
    orgList: state.getOrgByUserRed.data,
  }
}


interface SideNavProps {
  orgList?: { organizations: []; groups: [] }
}

interface ParentProps {
  parentLabel: string
  parentPath: string
  title: string
  childMenu?: object[]
  icon?: any
}

interface SubModule1 {
  name: string
  active: boolean
}

interface SelectedModuleProps {
  name: string
  active: boolean
  expanded?: boolean
  subModule1?: SubModule1
}

let initialSelectedModule = { name: '', expanded: false, active: false, subModule1: { name: '', active: false } }
export const SideNavWpp = ({ orgList }: SideNavProps) => {
  const app_ver = appConfig.UI_VERSION
  const base_url = appConfig.RA_HOST_URL
  console.log('App Version >>>>>', app_ver)
  console.log('Base urls >>>>>', base_url)
  const location = useLocation()
  const [selectedModule, setSelectedModule] = useState<SelectedModuleProps>(initialSelectedModule)
  const navigate = useNavigate()
  const activeIndicator = (fixedItem: any, dynamicItem: SelectedModuleProps, level: number, action: string) => {
    let selectedModuleIndicator: any = {
      name: '',
      expanded: false,
      active: false,
      subModule1: { name: '', active: false }
    }
    let parentPath = ''
    let childOneLevelPath = ''
    const returnDefault = (actionType: string) => {
      if (actionType === 'checkActive') {
        selectedModuleIndicator.active = false;
        return selectedModuleIndicator.active;
      } else if (actionType === 'checkExpand') {
        selectedModuleIndicator.expanded = false;
        return selectedModuleIndicator.expanded;
      }
    }
    switch (level) {
      case 1:
        if (_.isEqual(dynamicItem, initialSelectedModule)) {
          returnDefault(action)
        } else {
          parentPath =
            fixedItem?.parentPath?.split('/')?.filter((item: string) => item && item.length > 0)[0]
          selectedModuleIndicator['active'] = dynamicItem.name && parentPath === dynamicItem.name
          selectedModuleIndicator['expanded'] =
            dynamicItem.name && parentPath === dynamicItem.name && fixedItem?.childMenu?.length > 0
          if (action === 'checkActive') {
            return selectedModuleIndicator.active
          } else if (action === 'checkExpand') {
            return selectedModuleIndicator.expanded
          }
        }
        break
      case 2:
        parentPath = fixedItem.path.split('/').filter((item: string) => item && item.length > 0)[0]
        childOneLevelPath = fixedItem.path.split('/').filter((item: string) => item && item.length > 0)[1]
        if (
          dynamicItem?.subModule1?.name &&
          parentPath === dynamicItem?.name &&
          childOneLevelPath === dynamicItem?.subModule1.name
        ) {
          selectedModuleIndicator['subModule1'] = { active: true }
          return selectedModuleIndicator['subModule1']
        }
        break
      default:
        returnDefault(action)
        break
    }
  }

  const iconList = [
    { name: 'WppIconDataViewList', icon: <WppIconDataViewList slot="icon-start" data-testid="view-list-icon" /> },
    { name: 'WppIconLink', icon: <WppIconLink slot="icon-start" data-testid="link-icon" /> },
    { name: 'WppIconNote', icon: <WppIconNote slot="icon-start" /> },
    { name: 'WppIconChannel', icon: <WppIconChannel slot="icon-start" /> },
    { name: 'WppIconDatabase', icon: <WppIconDatabase slot="icon-start" /> },
    { name: 'WppIconLockOn', icon: <WppIconLockOn slot="icon-start" color="#B01C1C" /> },
    { name: 'WppIconDataHistogram', icon: <WppIconDataHistogram slot="icon-start" /> }
  ]

  const isUserAuthorized = (arr1: any, arr2: any) => {
    if (arr1) {
      const isUserAuth = arr1.some((item: any) => arr2.includes(item.name))
      return !isUserAuth
    }
    return true
  }

  const menuConfig = [
    {
      parentLabel: 'Consumer Preference Portal',
      parentPath: '/cpp/cpp-dashboard',
      title: 'Privacy',
      icon: 'WppIconDataViewList',
      childMenu: [
        { label: 'Dashboard', path: '/cpp/cpp-dashboard', isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.CPPA]) },
        { label: 'Flowchart', path: '/cpp/flowchart', isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.CPPA]) }
      ]
    },
    {
      parentLabel: 'Data Mapping',
      parentPath: '/dm/dm-dashboard',
      title: 'Mapping',
      icon: 'WppIconLink',
      childMenu: [
        { label: 'Dashboard', path: '/dm/dm-dashboard', isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.DPM]), },
        { label: 'Inventory', path: '/dm/data-mapping', isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.DPM]), },
        { label: 'Reports', path: '/dm/reports', isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.DPM]), }
      ]
    },
    {
      parentLabel: 'Vendor Risk Management',
      parentPath: '/vrm/vendors-list',
      title: 'Vendor Risk',
      icon: 'WppIconChannel',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
        USER_ROLE.SAD,
        USER_ROLE.DPM,
        USER_ROLE.SU
      ]),
      childMenu: [
        {
          label: 'Vendors',
          path: '/vrm/vendors-list',
          isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
            USER_ROLE.SAD,
            USER_ROLE.DPM,
            USER_ROLE.SU
          ])
        },
        {
          label: 'Vendors Dashboard',
          path: '/vrm/vendor-dashboard',
          isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
            USER_ROLE.SAD,
            USER_ROLE.DPM,
            USER_ROLE.SU
          ])
        },
        {
          label: 'Assessments',
          path: '/vrm/assessments-list',
          isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
            USER_ROLE.SAD,
            USER_ROLE.DPM,
          ])
        },
        {
          label: 'Assessments Dashboard',
          path: '/vrm/assessment-dashboard',
          isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
            USER_ROLE.SAD,
            USER_ROLE.DPM,
          ])
        },
        {
          label: 'Template',
          path: '/vrm/templates',
          isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
            USER_ROLE.SAD
          ])
        }
      ]
    }
  ]

  const handleChangeRoute = (event: any) => {
    navigate(event.detail.path)
  }

  useLayoutEffect(() => {
    const modifiedLocation = location.pathname.split('/').filter(item => item.length > 0)
    let dynamicItem: any = {}
    for (const [key, value] of Object.entries(modifiedLocation)) {
      if (+key === 0) {
        dynamicItem['name'] = value
      } else if (+key > 0) {
        dynamicItem['expanded'] = true
        if (+key === 1) {
          dynamicItem['subModule1'] = { name: value }
        }
      }
    }
    setSelectedModule(dynamicItem)
  }, [location])

  const getVersionNumber = (str: string) => {
    if (str !== null && str !== undefined) {
      const parts = str.split('-')
      const version = parts[parts.length - 1]
      return version
    } else {
      return ''
    }
  }

  return (
    <div className={styles.primaryNav}>
      <WppNavSidebar initialPath={location.pathname} onWppChange={handleChangeRoute}>
        <div className={styles.sideNavHead}>
          <Typography variant="h4">WPP Safer Data Navigator</Typography>
        </div>
        <WppDivider />
        {menuConfig
            ?.filter(
              (parent: ParentProps) =>
                (appConfig.NPROD === PROD && parent.parentLabel !== DATA_MAPPING) ||
                appConfig.NPROD === NPROD
            )
            .filter(Boolean)
            .map((parent: ParentProps) => {
              return (
                <React.Fragment key={`${parent?.icon}_${parent?.title}`}>
                  <WppNavSidebarItem
                    key={`${parent?.icon}_${parent?.title}`}
                    maxTitleLengthWithSubItems={30}
                    label={parent.parentLabel}
                    path={parent.parentPath}
                    groupTitle={parent.title}
                    active={activeIndicator(parent, selectedModule, 1, 'checkActive')}
                    expanded={activeIndicator(parent, selectedModule, 1, 'checkExpand')}
                    extended={parent.childMenu && parent.childMenu.length > 0}
                    data-testid={parent.parentLabel}
                  >
                    <>
                      {iconList.find(icon => icon.name === parent.icon)?.icon}
                    </>
                    {
                      parent?.childMenu?.map((child: any, childIdx: number) => (
                        <WppNavSidebarItem
                          className={`${
                            !child.isAuthorised
                              ? styles.customNavItemCategory
                              : styles.customNavItemCategory + ' ' + styles.disable
                          }`}
                          key={`${child?.label}-${childIdx}`}
                          label={child.label}
                          active={activeIndicator(child, selectedModule, 2, 'checkActive')}
                          path={child.path}
                          data-testid={`${parent.parentLabel}-${child.label}`}
                        >
                          <>{child.isAuthorised && iconList[5]?.icon}</>
                        </WppNavSidebarItem>
                      ))}
                  </WppNavSidebarItem>
                  <WppDivider />
                </React.Fragment>
              )
            })}
        <p className={styles.uiVersion} data-testid='sidenav-build-version'>Build Version: {getVersionNumber(appConfig.UI_VERSION)}</p>
      </WppNavSidebar>
    </div>
  )
}

export default connect(mapStateToProps, null)(SideNavWpp)
