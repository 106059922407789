import React, { forwardRef, memo, useCallback, useEffect, useRef, useState, ChangeEvent } from 'react'

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import FeedbackIcon from '@mui/icons-material/Feedback'
import FlagIcon from '@mui/icons-material/Flag'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { TabsChangeEventDetail } from '@wppopen/components-library'
import {
  WppAccordion,
  WppActionButton,
  WppBanner,
  WppButton,
  WppIconAvailableCheckmark,
  WppIconClose,
  WppIconInfo,
  WppTooltip,
  WppTypography,
  WppModal,
  WppSelect,
  WppListItem,
  WppTabs,
  WppTab
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { connect } from 'react-redux'

import TextArea from 'components/textArea/TextArea'

import CommentHistory from './CommentHistory'
import CommentSection from './CommentSection'
import MultiSelect from './MultiSelect'
import styles from './QueAnsAssessment.module.scss'
import RenderAttachment from './RenderAttachment'
import Review from './Review'
import Save from './Save'
import SingleSelect from './SingleSelect'
import Text from './Text'
import { getAssessmentByIdDispatcher, updateRowData } from '../../containers/piaModule/assessmentList/rowDetails/action'
import { ASSESSMENT, QUE_TYPE, RISK_LEVEL, REVIEW_STATUS, GROUPS, STATUS, USER_ROLE } from '../../helper/constants'
import { parseQuestionBankIds, prepareAssessmentDataHelper } from '../../helper/Helper'
import serviceURL from '../../helper/serviceURL'
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'
interface Comment {
  id: string | object
  comment: string
}

interface MultiSelectResponse {
  replacedString: string
  lastItemRemoved: string
}

const getMultiSelectText = (responseString: string = ''): MultiSelectResponse => {
  let parts = responseString?.split('$$')
  let lastItemRemoved = parts?.length > 1 ? parts.pop()! : ''
  let replacedString = parts?.join('|') || ''
  return {
    replacedString,
    lastItemRemoved
  }
}

interface SaveCommentRisk {
  questionId?: string
  risky: boolean
  comment: string
}

let initialCommentState: Comment = {
  id: {},
  comment: ''
}
let initialObjParser: SaveCommentRisk = {
  questionId: '',
  comment: '',
  risky: false
}
let initialSelectState = {
  id: '',
  response: ''
}

interface QuestionTobeFetched {
  assessId: null | string
  categoryId?: null | string
  questionId?: null | string
}


interface PostData extends SelectedQuestion{
  modifiedRiskScore: number;
  status: string; 
  comments: string; 
}

interface SelectedQuestion {
  assessmentId: string;
  comments: string;
  control: string;
  modifiedRiskScore: number;
  questionBankId: string;
  riskLevel: string;
  riskScore: number;
  status: string;
}

const SELECTEDQUESTION_INITIAL_STATE = {
  assessmentId: '', 
  comments: '', 
  control: '', 
  modifiedRiskScore: 0, 
  questionBankId: '',  
  riskLevel: '', 
  riskScore: 0, 
  status: ''
}

type RiskLevelKey = keyof typeof RISK_LEVEL;

const mapStateToProps = (state: any) => {
  return {
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    getOrgByUserRed: state.getOrgByUserRed.data,
    assessRowDetails: state.assessmentRowDetailsRed.data,
    userContext: state.getOrgByUserRed.context,
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  getAssessmentByIdDispatcher: (id: string, head: object) => dispatch(getAssessmentByIdDispatcher(id, head)),
  responseStatsDispatcher: (data: any) => dispatch(updateRowData(data))
})

function updateTotalComments(originalJson: any, categoryId: any, questionId: any) {
  const updatedJson = JSON.parse(JSON.stringify(originalJson))

  if (updatedJson.questionByCategories[categoryId]) {
    updatedJson.questionByCategories[categoryId] = updatedJson.questionByCategories[categoryId].map((item: {question: {id: number}, totalComments: number})  => {
      if (item.question.id === questionId) {
        return {
          ...item,
          totalComments: ++item.totalComments
        }
      }
      return item
    })
  }

  return updatedJson
}

function updateRisk(originalJson: any, categoryId: any, questionId: any, status: string, riskScore: number, riskLevel: string = '') {
  const updatedJson = JSON.parse(JSON.stringify(originalJson))

  if (updatedJson.questionByCategories[categoryId]) {
    updatedJson.questionByCategories[categoryId] = updatedJson.questionByCategories[categoryId].map((item: {question: {id: number}, riskDetails: object}) => {
      if (item.question.id === questionId) {
        return {
          ...item,
          riskDetails: { ...item.riskDetails, status, riskLevel }
        }
      }
      return item
    })
  }
  updatedJson.riskScore = riskScore;
  return updatedJson
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    top: '-6px',
    border: '1px solid #e3e3e3',
    fontSize: '9px',
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    padding: '8px',
    right: '-4px'
  }
}))

const SaveSection = memo(
  forwardRef((props: any) => {
    const [showSaveSpinner, setShowSaveSpinner] = useState(false)
    const [changeDetected, setChangeDetected] = useState(false)
    const saveReviewData = (saveIdx: string) => {
      if (changeDetected) {
        setShowSaveSpinner(true)
        const parseObjRes = parseQuestionBankIds(saveIdx)
        setTimeout(() => {
          props?.executeSave(props.commentRefs, parseObjRes)
          setShowSaveSpinner(false)
        }, 3000)
      }
    }
    useEffect(() => {
      if (props.commentRefs.comment.trim().length > 0) {
        if (!_.isEqual(props.commentRefs, initialCommentState)) {
          setChangeDetected(true)
        }
      } else {
        setChangeDetected(false)
      }
    }, [props.commentRefs])

    return (
      <div>
        <WppButton
          key={`-save-${props.saveKey}`}
          className={styles.customBtnSecondary}
          variant="secondary"
          size="s"
          disabled={!changeDetected}
          loading={showSaveSpinner}
          onClick={() => saveReviewData(props.saveKey)}
        >
          Save{' '}
        </WppButton>
      </div>
    )
  })
)

const findOpenRiskStatusQuestions = (data: any) => {
  return data.details.flatMap((category: { questionAnswers: any[] }) =>
    category.questionAnswers
      .filter(
        (answer: { riskDetails: { status: string } }) => answer.riskDetails && answer.riskDetails.status === 'OPEN'
      )
      .map((answer: any) => answer?.riskDetails?.questionBankId)
  )
}

const removeItemMatchByQuestionBankId = (data: any, questionBankIdToRemove: any) => {
  return data.filter((question: any) => question !== questionBankIdToRemove)
}

const QuesAnsAssessment = memo(
  ({
    assessment,
    selectedSideOrg,
    getOrgByUserRed,
    getAssessmentByIdDispatcher,
    responseStatsDispatcher,
    showApproveToast,
    closeSideModal,
    assessRowDetails,
    prepareAssessmentData,
    userContext,
    apiTriggger,
  }: any) => {
    const [showReview, setShowReview] = useState(false)
    const handleReviewMemoised = useCallback((idx: string, e: any) => handleReview(idx, e), [showReview])
    const [showCommentIdx, setShowCommentIdx] = useState<string | null>(null)
    const [commentGetter, setCommentGetter] = useState({ id: {}, comment: '' })
    const [saveDynamicObjParser, setSaveDynamicObjParser] = useState(initialObjParser)
    const [saveDynamicDpmSingleSelectObjParser, setSaveDynamicDpmSingleSelectObjParser] = useState(initialSelectState)
    const [saveDynamicDpmMultiSelectObjParser, setSaveDynamicDpmMultiSelectObjParser] = useState(initialSelectState)
    const [saveDynamicDpmTextInventoryObjParser, setSaveDynamicDpmTextInventoryObjParser] = useState(initialSelectState)
    const commentRef = useRef(null)
    const saveRef = useRef(null)
    const [showBanner, setShowBanner] = useState(false)
    const initialState = { id: 0, name: '' }
    const [selectReviewOutcome, setSelectReviewOutcome] = useState(initialState)
    const [showOutcomeBanner, setShowOutcomeBanner] = useState(false)
    const [showReviewOutcomeSaveSpinnner, setShowReviewOutcomeSaveSpinnner] = useState(false)
    const [loadQueAndAns, setLoadQueAndAns] = useState(false)
    const [showPmQnEdit, setShowPmQnEdit] = useState(false)
    const handlePmQnEditMemoised = useCallback((idx: string, e: any) => handleDpmQn(idx, e), [showPmQnEdit])
    const dpmSaveRef = useRef(null)
    const [dpmQnIdx, setDpmQnIdx] = useState<string | null>(null)
    const [tempQnBank, setTempQnBank] = useState<any>([])
    const [dpmReviewComment, setDpmReviewComment] = useState('')
    const [getComments, setGetComments] = useState({ resId: '', value: [] })
    const [dpmQnRetrievedError, setDpmQnRetrievedError] = useState(false)
    const [dpmQnRetrievingSpinner, setDpmQnRetrievingSpinner] = useState(false)
    const [dpmQnSaveSpinner, setDpmQnSaveSpinner] = useState(false)
    const [riskStatusOpen, setRiskStatusOpen] = useState<Array<any>>([])
    const [currentTab, setCurrentTab] = useState('quickSummary')
    const {
      osApi: { getAccessToken }
    } = useOs()

    const headers = {
      accept: '*/*',
      Authorization: 'Bearer ' + getAccessToken()
    }
    const [accordionDetails, setAccordionDetails] = useState({ expanded: false, catName: '' })
    const [questionDetailsTobeFectched, setQuestionDetailsTobeFectched] = useState<QuestionTobeFetched>({
      assessId: null,
      categoryId: null,
      questionId: null
    })
    const [savedResponse, setSavedResponse] = useState([])
    const [singleSelectSuccess, setSingleSelectSuccess] = useState(false)
    const [multiSelectSuccess, setMultiSelectSuccess] = useState(false)
    const [textInvSuccess, setTextInvSuccess] = useState(false)
    const [singleSelectFailure, setSingleSelectFailure] = useState(false)
    const [multiSelectFailure, setMultiSelectFailure] = useState(false)
    const [textInvFailure, setTextInvFailure] = useState(false)
    const [forceNonDpmSaveToast, setForceNonDpmSaveToast] = useState(false)
    const qnSaveTimer = useRef<any>(null)
    const commentSaveTimer = useRef<any>(null)

    const [isRiskModalOpen, setIsRiskModalOpen] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState<SelectedQuestion>(SELECTEDQUESTION_INITIAL_STATE)
    const [selectedRiskStatus, setSelectedRiskStatus] = useState<any>({})
    const [selectedRiskScore, setSelectedRiskScore] = useState('')
    const [approverComments, setApproverComments] = useState('')
    const [riskStatusList, setRiskStatusList] = useState([])
    const [categoryId, setCategoryId] = useState(null)
    const { axiosInstance } = useAxiosInterceptors()

    const userRole = getOrgByUserRed?.groups?.map((group: { name: string }) => group?.name)

    const riskFlagIndicator = (getRiskLevel: string, status: any) => {
      let flag = null
      switch (getRiskLevel) {
        case 'LOW':
          flag = <FlagIcon style={{ color: RISK_LEVEL.LOW }} fontSize="medium" className={styles.flagRisk} />
          break
        case 'MEDIUM':
          flag = <FlagIcon style={{ color: RISK_LEVEL.MEDIUM }} fontSize="medium" className={styles.flagRisk} />
          break
        case 'HIGH':
          flag = <FlagIcon style={{ color: RISK_LEVEL.HIGH }} fontSize="medium" className={styles.flagRisk} />
          break
        case 'VERY_HIGH':
          flag = <FlagIcon style={{ color: RISK_LEVEL.VERY_HIGH }} fontSize="medium" className={styles.flagRisk} />
          break
        case 'CRITICAL':
          flag = <FlagIcon style={{ color: RISK_LEVEL.CRITICAL }} fontSize="medium" className={styles.flagRisk} />
          break
        default:
          flag = <FlagIcon style={{ color: RISK_LEVEL.NO_RISK }} fontSize="medium" className={styles.flagRisk} />
          break
      }
      return (
        <>
          {flag}
          {status === 'APVD' && <DoneIcon className={styles.doneIcon} fontSize="medium" />}
        </>
      )
    }

    const findTag = (whichNode: any, el: any, tag: any) => {
      if (whichNode === 'parentNode') {
        while (el.parentNode) {
          el = el.parentNode
          if (el.className.includes(tag)) return el
        }
        return null
      } else {
        for (const child of el.children) {
          if (child.className.includes(tag)) {
            return el
          } else {
            return null
          }
        }
      }
    }

    const showFeedback = () => {
      setShowReview(true)
      setGetComments({ resId: '', value: [] })
    }

    useEffect(() => {
      fetchTypeOfRiskStatusList('ASSESSMENT_RISK_STATUS')
    }, [])

    const fetchTypeOfRiskStatusList = (labelType: string) => {
      const apiUrl = serviceURL.pgpBaseAPI + `/api/common/form-config/?lableType=${labelType}`

      axiosInstance
        .get(apiUrl, {
          headers: headers
        })
        .then(res => {
          setRiskStatusList(res.data)
        })
        .catch(error => {
          console.log(error)
        })
    }

    
    const fetchCommentsDetails = async (id: string, assessmentId: string) => {
      const apiUrl = serviceURL.pgpBaseAPI + `/api/assessments/risks/${assessmentId}/${id}`
      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: headers
        })
        return response?.data
      } catch (error) {
        console.log(error)
      }
    }
    const selectedQuestionFn = async (que: any, assessment: any, categoryId: any) => {
      const res = await fetchCommentsDetails(que?.question?.id, assessment?.assessmentId)
      setIsRiskModalOpen(true)
      setCategoryId(categoryId)
      setSelectedRiskScore(res?.riskScore)
      setSelectedRiskStatus(res?.status)
      setSelectedQuestion(res)
      setApproverComments(res?.comments)
    }

    const saveSelectedRisks = () => {
      const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/risks'
      const postData: PostData = { ...selectedQuestion }
      postData['modifiedRiskScore'] = +selectedRiskScore == null ? -1 : +selectedRiskScore
      postData['status'] = selectedRiskStatus
      postData['comments'] = approverComments
      axiosInstance
        .post(apiUrl, postData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp) => {
          const riskScore = resp?.data?.riskScore
          const riskLevel = resp?.data?.riskLevel
          setShowBanner(false)
          setIsRiskModalOpen(false)
          if(postData?.modifiedRiskScore !== postData?.riskScore){
            apiTriggger(true)
          } else if (postData?.status === STATUS.OPEN) {
            setRiskStatusOpen([...riskStatusOpen, postData?.questionBankId])
          } else {
            const newRiskData = removeItemMatchByQuestionBankId(riskStatusOpen, postData?.questionBankId)
            setRiskStatusOpen(newRiskData)
          }
          const updatedData = updateRisk(assessRowDetails, categoryId, postData?.questionBankId, postData?.status, riskScore, riskLevel)
          prepareAssessmentData(updatedData)
          responseStatsDispatcher(updatedData)
        })
        .catch(error => {
          console.log(error)
          setShowBanner(true)
        })
    }
    const handleReview = (reviewIdx: any, e: any) => {
      e.preventDefault()
      if (e.target.className) {
        if (!!e.target.className.baseVal) {
          if (e.target.className.baseVal === '' || e.target.className.baseVal.includes('MuiSvgIcon-root')) {
            showFeedback()
          } else if (e.target.className.includes('wpp-action-button')) {
            if (e.target.children[0].className === 'wpp-icon wpp-icon-cross') {
              setShowReview(false)
            } else if (e.target.children[0].className === 'wpp-tooltip') {
              if (findTag('childNode', e.target.children[0], 'feedback-area')) {
                showFeedback()
              }
            } else if (e.target.children[0].className === 'feedback-area') {
              showFeedback()
            }
          } else if (e.target.className.includes('wpp-tooltip')) {
            if (findTag('childNode', e.target, 'feedback-area')) {
              showFeedback()
            }
          } else if (e.target.className.includes('wpp-icon-cross')) {
            setShowReview(false)
          } else if (e.target.className.includes('feedback-area')) {
            showFeedback()
          }
        } else if (
          e.target.className.baseVal?.length === 0 ||
          e.target.className.baseVal?.includes('MuiSvgIcon-root')
        ) {
          showFeedback()
        } else if (e.target.className.includes('wpp-icon-cross')) {
          setShowReview(false)
        } else if (e.target.className.includes('wpp-action-button')) {
          if (e.target.className.includes('wpp-icon-cross')) {
            setShowReview(false)
          } else if (e.target.children[0].className === 'wpp-tooltip') {
            if (findTag('childNode', e.target.children[0], 'feedback-area')) {
              showFeedback()
            }
          } else if (e.target.children[0].className.includes('wpp-icon-cross')) {
            setShowReview(false)
          } else if (e.target.children[0].className.includes('feedback-area')) {
            showFeedback()
          }
        } else if (e.target.className.includes('feedback-area')) {
          showFeedback()
        }
      }
      setShowCommentIdx(reviewIdx)
    }
    const accordionHandler = (e: any, catName: string) => {
      setAccordionDetails({ expanded: e.detail.expanded, catName: catName })
    }

    const showEdit = () => {
      setShowPmQnEdit(true)
      setSingleSelectFailure(false)
      setMultiSelectFailure(false)
      setTextInvFailure(false)
    }
    const hideEdit = () => {
      setShowPmQnEdit(false)
      setSingleSelectFailure(false)
      setMultiSelectFailure(false)
      setTextInvFailure(false)
    }
    const handleDpmQn = (editIdx: any, e: any) => {
      e.preventDefault()
      if (e.target.className) {
        if (!!e.target.className.baseVal) {
          if (e.target.className.baseVal === '' || e.target.className.baseVal.includes('MuiSvgIcon-root')) {
            showEdit()
          } else if (e.target.className.includes('wpp-action-button')) {
            if (e.target.children[0].className === 'wpp-icon wpp-icon-cross') {
              hideEdit()
            } else if (e.target.children[0].className === 'wpp-tooltip') {
              if (findTag('childNode', e.target.children[0], 'edit-area')) {
                showEdit()
              }
            } else if (e.target.children[0].className === 'edit-area') {
              showEdit()
            }
          } else if (e.target.className.includes('wpp-tooltip')) {
            if (findTag('childNode', e.target, 'edit-area')) {
              showEdit()
            }
          } else if (e.target.className.includes('wpp-icon-cross')) {
            hideEdit()
          } else if (e.target.className.includes('edit-area')) {
            showEdit()
          }
        } else if (
          e.target.className.baseVal?.length === 0 ||
          e.target.className.baseVal?.includes('MuiSvgIcon-root')
        ) {
          showEdit()
        } else if (e.target.className.includes('wpp-icon-cross')) {
          hideEdit()
        } else if (e.target.className.includes('wpp-action-button')) {
          if (e.target.className.includes('wpp-icon-cross')) {
            hideEdit()
          } else if (e.target.children[0].className === 'wpp-tooltip') {
            if (findTag('childNode', e.target.children[0], 'edit-area')) {
              showEdit()
            }
          } else if (e.target.children[0].className.includes('wpp-icon-cross')) {
            hideEdit()
          } else if (e.target.children[0].className.includes('edit-area')) {
            showEdit()
          }
        } else if (e.target.className.includes('edit-area')) {
          showEdit()
        }
      }
      setDpmQnIdx(editIdx)
    }
    const receiveComment = useCallback(
      (val: { id: {}; comment: '' }) => {
        setCommentGetter({ id: val.id, comment: val.comment })
      },
      [commentGetter]
    )
    const saveQuestion = useCallback(
      (commentVal: any, ids: any) => {
        setSaveDynamicObjParser(ids)
        const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/' + ids.responseId + '/comment'
        const postData = {
          comment: commentVal.comment
        }
        axiosInstance
          .post(apiUrl, postData['comment'], {
            headers: {
              'Content-Type': 'text/plain'
            }
          })
          .then(() => {
            setShowBanner(false)
          })
          .catch(error => {
            console.log(error)
            setShowBanner(true)
          })
        const getCommentsApiUrl = serviceURL.pgpBaseAPI + '/api/pm/comments/' + ids.responseId
        setTimeout(() => {
          //API is not reflecting changes immediately
          axiosInstance
            .get(getCommentsApiUrl)
            .then(res => {
              if (res?.data?.length) {
                setGetComments({ resId: ids.responseId, value: res.data?.length && res.data })
                const updatedData = updateTotalComments(assessRowDetails, ids.categoryId, ids.questionId)
                prepareAssessmentData(updatedData)
                responseStatsDispatcher(updatedData)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }, 1500)
      },
      [saveDynamicObjParser, getComments]
    )

    function updateFiles(originalJson: any, categoryId: any, questionId: any, files: any) {
      const updatedJson = JSON.parse(JSON.stringify(originalJson))
      if (updatedJson.questionByCategories[categoryId]) {
        updatedJson.questionByCategories[categoryId] = updatedJson.questionByCategories[categoryId].map((item: {question: {id: string}}) => {
          if (item.question.id === questionId) {
            return {
              ...item,
              attachments: files
            }
          }
          return item
        })
      }
    
      return updatedJson
    }
    

    const updateFile = (params: any) => {
      const parseKey = parseQuestionBankIds(params.dynamicKey)
      const updatedData = updateFiles(assessRowDetails, parseKey.categoryId, parseKey.questionId, params.attachedFiles)
      prepareAssessmentData(updatedData)
      responseStatsDispatcher(updatedData)
    }

    const isEligibleForUpload = () => {
      return (isEligibleForReview() && (assessRowDetails.status === STATUS.REVIEW_PENDING || assessRowDetails.status === STATUS.RESPONSE_PENDING))
    }

    const renderResponse = (que: any, attachmentFile: Array<{ filename: string }> | null, dynamicKey: string, response: any = null) => {
      if (que.description !== 'Review Comments') {
        switch (que.questionType) {
          case QUE_TYPE.SINGLE_SELECT:
          case QUE_TYPE.SINGLE_SELECT_TEXT:
          case QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL:
          case QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL_ATTACHMENT:
          case QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL: {
            const inputTextResponse = getMultiSelectText(response)
            return (
              <>
                <SingleSelect response={response} />
                {inputTextResponse.lastItemRemoved !== '' && <Text response={inputTextResponse.lastItemRemoved} />}
                {que.attachment && <RenderAttachment
                  attachmentFile={attachmentFile}
                  queId={que?.id}
                  assessmentId={assessment?.assessmentId}
                  isValidToViewUpload={isEligibleForUpload()}
                  updateData={updateFile}
                  dynamicKey={dynamicKey}
                />}

              </>
            )
          }
          case QUE_TYPE.MULTI_SELECT:
            return <MultiSelect question={que} response={response} />
          case QUE_TYPE.MULTI_SELECT_TEXT:
          case QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL:
          case QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL_ATTACHMENT:
          case QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL:
            const inputTextResponse = getMultiSelectText(response)
            return (
              <div className={styles.multiSelectInputText}>
                <MultiSelect question={que} response={inputTextResponse.replacedString} />
                {inputTextResponse.lastItemRemoved !== '' && <Text response={inputTextResponse.lastItemRemoved} />}
                {que.attachment && <RenderAttachment
                  attachmentFile={attachmentFile}
                  queId={que?.id}
                  assessmentId={assessment?.assessmentId}
                  isValidToViewUpload={isEligibleForUpload()}
                  updateData={updateFile}
                  dynamicKey={dynamicKey}
                />}

              </div>
            )
          case QUE_TYPE.TEXT:
          case QUE_TYPE.PRESELECTED:
            return (
              <>
                <Text response={response} />
                {que.attachment && <RenderAttachment
                  attachmentFile={attachmentFile}
                  queId={que?.id}
                  assessmentId={assessment?.assessmentId}
                  isValidToViewUpload={isEligibleForUpload()}
                  updateData={updateFile}
                  dynamicKey={dynamicKey}
                />}

              </>
            )
          default:
            return response
        }
      } else {
        return null
      }
    }

    useEffect(() => {
      if (
        assessment &&
        !_.isEqual(assessment, {
          assessmentId: '1',
          assessmentName: '',
          details: [],
          isAllQnNonEmpty: false
        }) &&
        assessment.isAllQnNonEmpty
      ) {
        setLoadQueAndAns(true)
        let pmRes: any = []
        pmRes = Object.values(assessment?.details).filter(
          (cat: any) => cat && cat.categoryName === ASSESSMENT.DATA_PRIVACY_MANAGER
        )
        if (pmRes[0]?.questionAnswers?.length) {
          setTempQnBank(pmRes[0]?.questionAnswers)
        }
      }
    }, [assessment, accordionDetails])
    useEffect(() => {
      if (questionDetailsTobeFectched.assessId) {
        if (selectedSideOrg) {
          setDpmQnRetrievingSpinner(true)
            getAssessmentByIdDispatcher?.(questionDetailsTobeFectched.assessId, headers, selectedSideOrg.id)
              .then((res: any) => {
                const apiUpdatedData = prepareUpdatedAssessmentData(res, getOrgByUserRed.groups)
                setSavedResponse(apiUpdatedData)
                setDpmQnRetrievedError(false)
                setDpmQnRetrievingSpinner(false)
              })
              .catch((err: any) => {
                setDpmQnRetrievedError(true)
                setDpmQnRetrievingSpinner(false)
                console.log(err)
              })
        }
      }
    }, [getOrgByUserRed, questionDetailsTobeFectched])
    useEffect(() => {
      if (assessment?.details.length) {
        const riskStatus = findOpenRiskStatusQuestions(assessment)
        setRiskStatusOpen(riskStatus)
      }
    }, [assessment])
    useEffect(() => {
      setShowPmQnEdit(false)
    }, [savedResponse])
    useEffect(() => {
      if (!_.isEqual(saveDynamicDpmSingleSelectObjParser, initialSelectState) && !dpmQnRetrievedError) {
        setSingleSelectSuccess(true)
        setSaveDynamicDpmTextInventoryObjParser(initialSelectState)
        setSaveDynamicDpmMultiSelectObjParser(initialSelectState)
      }
      if (!_.isEqual(saveDynamicDpmMultiSelectObjParser, initialSelectState) && !dpmQnRetrievedError) {
        setMultiSelectSuccess(true)
        setSaveDynamicDpmTextInventoryObjParser(initialSelectState)
        setSaveDynamicDpmSingleSelectObjParser(initialSelectState)
      }
      if (!_.isEqual(saveDynamicDpmTextInventoryObjParser, initialSelectState) && !dpmQnRetrievedError) {
        setTextInvSuccess(true)
        setSaveDynamicDpmMultiSelectObjParser(initialSelectState)
        setSaveDynamicDpmSingleSelectObjParser(initialSelectState)
      }
      if (!_.isEqual(saveDynamicObjParser, initialObjParser) && !dpmQnRetrievedError) {
        setForceNonDpmSaveToast(true)
      }
    }, [
      saveDynamicDpmSingleSelectObjParser,
      saveDynamicDpmMultiSelectObjParser,
      saveDynamicDpmTextInventoryObjParser,
      dpmQnRetrievedError
    ])

    useEffect(() => {
      if (singleSelectSuccess) {
        qnSaveTimer.current = setTimeout(() => {
          setSingleSelectSuccess(false)
        }, 1000)
      }
      if (multiSelectSuccess) {
        qnSaveTimer.current = setTimeout(() => {
          setMultiSelectSuccess(false)
        }, 1000)
      }
      if (textInvSuccess) {
        qnSaveTimer.current = setTimeout(() => {
          setTextInvSuccess(false)
        }, 1000)
      }
      if (forceNonDpmSaveToast) {
        commentSaveTimer.current = setTimeout(() => {
          setForceNonDpmSaveToast(false)
        }, 1000)
      }
      return () => {
        if (commentSaveTimer.current) {
          clearTimeout(commentSaveTimer.current)
        }
        if (qnSaveTimer.current) {
          clearTimeout(qnSaveTimer.current)
        }
      }
    }, [singleSelectSuccess, multiSelectSuccess, textInvSuccess, forceNonDpmSaveToast])

    useEffect(() => {
      if (!showPmQnEdit) {
        if (savedResponse.length === 0) {
          setTempQnBank([])
        } else {
          setTempQnBank([])
        }
      }
    }, [showPmQnEdit])
    const prepareUpdatedAssessmentData = (data: any, usergroups: any[]) => {
      const _assessment = prepareAssessmentDataHelper(data, usergroups, selectedSideOrg, getOrgByUserRed)
      return _assessment?.details.length ? _assessment.details : []
    }
    const pdmResponseHolder = {
      singleSelectHandler: {
        updateSingleSelect: useCallback(
          (updatedSingleSelectFromChild: any) => {
            setTempQnBank(updatedSingleSelectFromChild)
          },
          [tempQnBank]
        ),
        saveSingleSelect: useCallback(
          (obj: any, val: any) => {
            setDpmQnSaveSpinner(true)
            setSaveDynamicDpmSingleSelectObjParser({ ...saveDynamicDpmSingleSelectObjParser, id: obj.questionId })
            const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/assessment/' + obj.assessId + '/response/save'
            const postData = {
              questionBankId: obj.questionId,
              responseType: val.question.questionType,
              responseValue: val.response.responseValue,
              responseOptionId: val.response?.id || ''
            }
            axiosInstance
              .put(apiUrl, postData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(() => {
                setSingleSelectFailure(false)
                setQuestionDetailsTobeFectched({
                  assessId: obj.assessId
                })
                setSaveDynamicDpmSingleSelectObjParser({ id: obj.questionId, response: val.response.responseValue })
                setDpmQnSaveSpinner(false)
              })
              .catch(error => {
                console.log(error)
                setSingleSelectFailure(true)
                setDpmQnSaveSpinner(false)
              })
          },
          [saveDynamicDpmSingleSelectObjParser, selectedSideOrg]
        )
      },
      multiSelectHandler: {
        updateMultiSelect: useCallback(
          (updatedMultipleSelectFromChild: any) => {
            setTempQnBank(updatedMultipleSelectFromChild)
          },
          [tempQnBank]
        ),
        saveMultiSelect: useCallback(
          (obj: any, val: any) => {
            setDpmQnSaveSpinner(true)
            setSaveDynamicDpmMultiSelectObjParser({ ...saveDynamicDpmMultiSelectObjParser, id: obj.questionId })
            const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/assessment/' + obj.assessId + '/response/save'
            const postData = {
              questionBankId: obj.questionId,
              responseType: val.question.questionType,
              responseValue: val.response?.responseValue,
              responseOptionId: val.response?.id || ''
            }
            axiosInstance
              .put(apiUrl, postData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(() => {
                setMultiSelectFailure(false)
                setQuestionDetailsTobeFectched({
                  assessId: obj.assessId
                })
                setSaveDynamicDpmMultiSelectObjParser({ id: obj.questionId, response: val.response.responseValue })
                setDpmQnSaveSpinner(false)
              })
              .catch(error => {
                console.log(error)
                setMultiSelectFailure(true)
                setDpmQnSaveSpinner(false)
              })
          },
          [saveDynamicDpmMultiSelectObjParser, selectedSideOrg]
        )
      },
      textInventoryHandler: {
        updateTextInventory: useCallback(
          (updatedTextInventory: any) => {
            setTempQnBank(updatedTextInventory)
          },
          [tempQnBank]
        ),
        saveTextInventory: useCallback(
          (obj: any, val: any) => {
            setSaveDynamicDpmTextInventoryObjParser({ ...saveDynamicDpmTextInventoryObjParser, id: obj.questionId })
            setDpmQnSaveSpinner(true)
            const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/assessment/' + obj.assessId + '/response/save'
            const postData = {
              questionBankId: obj.questionId,
              responseType: val.question.questionType,
              responseValue: val.response?.responseValue,
              responseOptionId: val.response?.id || ''
            }
            axiosInstance
              .put(apiUrl, postData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(() => {
                setTextInvFailure(false)
                setQuestionDetailsTobeFectched({
                  assessId: obj.assessId
                })
                setSaveDynamicDpmTextInventoryObjParser({ id: obj.questionId, response: val.response.responseValue })
                setDpmQnSaveSpinner(false)
              })
              .catch(error => {
                console.log(error)
                setTextInvFailure(true)
                setDpmQnSaveSpinner(false)
              })
          },
          [saveDynamicDpmTextInventoryObjParser, selectedSideOrg]
        )
      }
    }

    const hasRiskyQuestions = (questions: any) => {
      const riskLevels = questions
        ?.map((ele: any) => {
          return ele?.riskDetails?.riskLevel
        })
        .filter(Boolean)
      const priorityOrder = ['CRITICAL', 'VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW', 'NO_RISK']
      riskLevels.sort((a: string, b: string) => priorityOrder.indexOf(a) - priorityOrder.indexOf(b))
      const highestRisk: RiskLevelKey = riskLevels[0]
      if (highestRisk) {
        const isALLRiskApproved = questions.every((question: any) =>
          question?.riskDetails?.status === undefined || question?.riskDetails?.status === 'APVD'
        )
        return (
          <>
            <FlagIcon sx={{ color: RISK_LEVEL[highestRisk] }} fontSize="large" />
            {isALLRiskApproved && <DoneIcon className={styles.doneIconSection} fontSize="medium" />}
          </>
        )
      }
    }

    const hasAnyComments = (questions: any) => {
      const hasComments = questions.some((question: any) => question.totalComments > 0)
      if (hasComments) {
        return <ChatOutlinedIcon color="success" fontSize="medium" className={styles.commentIcon} />
      }
    }

    const handleApproverComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setApproverComments(e.target.value)
    }

    const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
      setCurrentTab(event.detail.value)
    }

    const prepareQuickSummaryData = (
      data: { categoryId: string; categoryName: string; questionAnswers: object[] }[]
    ) => {
      const menu = data
        ?.map((assessment: any) => {
          const eligibleQuestions = assessment?.questionAnswers
          // eslint-disable-next-line array-callback-return
            ?.map((item: { question: { configuration: string } }) => {
              // eslint-disable-next-line array-callback-return
              if (item?.question?.configuration?.trim() === '') return
              const config = JSON.parse(item?.question?.configuration)
              if (config.show === 'all') {
                return item
              }
            })
            ?.filter(Boolean)

          return eligibleQuestions?.length
            ? {
                categoryId: assessment?.categoryId,
                categoryName: assessment?.categoryName,
                questionAnswers: eligibleQuestions
              }
            : null
        })
        .filter((detail: any) => detail !== null)
      return menu
    }

    const hasRequiredAccess = (): boolean => {
      const isSAD = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.SAD].includes(item.name))
      const isDPM = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.DPM].includes(item.name))
      const isSU = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.SU].includes(item.name))
      const isSelfCreated =
        assessRowDetails?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim()
      const hasOwnNetwork = assessRowDetails?.agency?.organization?.id === getOrgByUserRed?.organizationId

      if (isSAD || (isDPM && hasOwnNetwork) || (isSU && isSelfCreated && hasOwnNetwork)) {
        return false
      }
      return true
    }

    const isEligibleForReview = () => {
      const isSAD = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.SAD].includes(item.name))
      const isDPM = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.DPM].includes(item.name))
      const hasOwnNetwork = assessRowDetails?.agency?.organization?.id === getOrgByUserRed?.organizationId
      return (isSAD || isDPM) && hasOwnNetwork;
    }

    return (
      <>
        <WppTabs className={styles.customTabs} value={currentTab} onWppChange={handleTabChange}>
          <WppTab className={styles.customTab} value="quickSummary">
            Quick Summary
          </WppTab>
          <WppTab className={styles.customTab} value="assessmentsDetails" disabled={hasRequiredAccess()}>
            Assessment Details
          </WppTab>
        </WppTabs>

        {
          {
            assessmentsDetails: (
              <>
                {isRiskModalOpen && (
                  <WppModal open={isRiskModalOpen} size="m" className={styles.modalBox}>
                    <div slot="header" className={styles.modalHeader}>
                      <h3>Risk Details</h3>
                      <WppIconClose
                        color="black"
                        onClick={() => setIsRiskModalOpen(false)}
                        className={styles.close}
                        size="m"
                      />
                    </div>
                    <p slot="body">
                      <div className={styles.mainBox} dangerouslySetInnerHTML={{ __html: selectedQuestion?.control }} />
                      <div className={styles['modal-row']}>
                        <div className={styles['col-1']}>
                          <WppTypography type="s-strong">Approver Comments</WppTypography>
                          <TextArea
                            value={approverComments}
                            placeholder="Enter Comments"
                            onChange={e => handleApproverComment(e)}
                          />
                          {selectedQuestion && (
                            <div className={styles.score}>
                              <WppTypography type="s-strong">Risk Score/Level : </WppTypography>
                              {selectedQuestion && (
                                <WppTypography type="s-midi">
                                  {selectedQuestion?.riskScore} / {selectedQuestion?.riskLevel}
                                </WppTypography>
                              )}
                            </div>
                          )}
                        </div>
                        <div className={styles['col-2']}>
                          <div className={styles['section-1']}>
                            <WppTypography type="s-strong"> Risk Status</WppTypography>
                            <WppSelect
                              className={styles.select}
                              onWppChange={e => setSelectedRiskStatus(e.target.value)}
                              placeholder="Placeholder"
                              value={selectedRiskStatus}
                            >
                              {riskStatusList?.map((ele: {code: string; name: string}) => {
                                return (
                                  <WppListItem value={ele?.code} key={ele?.code}>
                                    <p slot="label">{ele?.name}</p>
                                  </WppListItem>
                                )
                              })}
                            </WppSelect>
                          </div>
                          <div>
                            <WppTypography type="s-strong"> Adjust Risk Score</WppTypography>
                            <WppSelect
                              onWppChange={ev => setSelectedRiskScore(ev.target.value)}
                              placeholder="Select Risk Score"
                              className={styles.select}
                            >
                              <WppListItem value="">
                                <p slot="label">Select Risk Score</p>
                              </WppListItem>
                              <WppListItem value="0">
                                <p slot="label" className={styles['one']}>
                                  0
                                </p>
                              </WppListItem>
                              <WppListItem value={1}>
                                <p slot="label" className={styles['one']}>
                                  1
                                </p>
                              </WppListItem>
                              <WppListItem value={2}>
                                <p slot="label" className={styles['one']}>
                                  2
                                </p>
                              </WppListItem>
                              <WppListItem value={3}>
                                <p slot="label" className={styles['two']}>
                                  3
                                </p>
                              </WppListItem>
                              <WppListItem value={4}>
                                <p slot="label" className={styles['two']}>
                                  4
                                </p>
                              </WppListItem>
                              <WppListItem value={5}>
                                <p slot="label" className={styles['three']}>
                                  5
                                </p>
                              </WppListItem>
                              <WppListItem value={6}>
                                <p slot="label" className={styles['three']}>
                                  6
                                </p>
                              </WppListItem>
                              <WppListItem value={7}>
                                <p slot="label" className={styles['four']}>
                                  7
                                </p>
                              </WppListItem>
                              <WppListItem value={8}>
                                <p slot="label" className={styles['four']}>
                                  8
                                </p>
                              </WppListItem>
                            </WppSelect>
                          </div>
                        </div>
                      </div>
                    </p>
                    <div slot="actions" className={styles.modalAction}>
                      <WppButton
                        variant="secondary"
                        size="s"
                        className={styles.btn}
                        onClick={() => saveSelectedRisks()}
                        disabled={
                          assessRowDetails.status === STATUS.APPROVED || assessRowDetails.status === STATUS.REJECTED
                        }
                      >
                        Save
                      </WppButton>
                      <WppButton
                        variant="secondary"
                        className={styles.btn}
                        size="s"
                        onClick={() => setIsRiskModalOpen(false)}
                      >
                        Cancel
                      </WppButton>
                    </div>
                  </WppModal>
                )}
                {assessment.isAllQnNonEmpty ? (
                  <>
                    {loadQueAndAns &&
                      assessment.details.map((category: any) => {
                        return (
                          <>
                            {category?.categoryName !== ASSESSMENT.DATA_PRIVACY_MANAGER && (
                              <WppAccordion
                                size="m"
                                withDivider={false}
                                key={`cat-parent-accordian-container-catId-${category.categoryId}`}
                                className={
                                  category.categoryName !== ASSESSMENT.DATA_PRIVACY_MANAGER
                                    ? styles.accordStyle
                                    : ''
                                }
                                onWppChange={e => accordionHandler(e, category.categoryName)} //adhoc handler for API returning approver comments as question type
                              >
                                <WppTypography
                                  type="m-strong"
                                  slot="header"
                                  className={styles.accordHeader}
                                  key={`cat-parent-accordian-header-catId-${category.categoryId}`}
                                >
                                  {category.categoryName} ({category.questionAnswers.length}){' '}
                                  <div className={styles.groupIcons}>
                                    {userRole.includes(GROUPS.DPM) &&
                                      assessRowDetails.status !== STATUS.RESPONSE_PENDING &&
                                      hasRiskyQuestions(category.questionAnswers)
                                      }
                                      {userRole.includes(GROUPS.DPM) &&
                                      assessRowDetails.status !== STATUS.RESPONSE_PENDING &&
                                      hasAnyComments(category.questionAnswers)
                                      }
                                  </div>
                                </WppTypography>

                                <div className={styles.assessAcordianBody}>
                                  <div
                                    key={`cat-parent-accordian-body-catId-${category.categoryId}`}
                                    className={styles.catDescription}
                                  >
                                    {category.questionAnswers.map((que: any) => {
                                      return (
                                        <>
                                          {Object.keys(que).length ? (
                                            <div
                                              key={`que-container-queId-${que.question.id}`}
                                              className={styles.questionDetails}
                                            >
                                              <div
                                                key={`que-container-header-queId-${que.question.id}`}
                                                className={styles.qnHeaderContainer}
                                              >
                                                <p key={`que-header-tag-container-queId-${que.question.id}`}>
                                                  <span
                                                    className={styles.queTag}
                                                    key={`que-header-span-queId-${que.question.id}`}
                                                  >
                                                    {que.question.sequence} :{' '}
                                                  </span>
                                                  {que.question.description + ' '}
                                                  {que.question.subDescription?.trim().length > 0 && (
                                                    <WppTooltip
                                                      header="Description"
                                                      text={que.question.subDescription}
                                                      key={`que-header-tooltip-queId-${que.question.id}`}
                                                    >
                                                      <WppIconInfo
                                                        key={`que-header-tooltip-icon-queId-${que.question.id}`}
                                                      />
                                                    </WppTooltip>
                                                  )}
                                                </p>
                                                {category.categoryName === ASSESSMENT.DATA_PRIVACY_MANAGER && (
                                                  <div
                                                    className={styles.qnPmMarker}
                                                    key={`que-pm-edit-container-queId-${que.question.id}`}
                                                  >
                                                    {singleSelectSuccess &&
                                                      saveDynamicDpmSingleSelectObjParser.id === que.question.id && (
                                                        <div className={styles.qnSaved}>
                                                          <WppIconAvailableCheckmark
                                                            size="m"
                                                            color="#2e7d32"
                                                            className={styles.qnSavedIcon}
                                                          />
                                                          &nbsp;
                                                          <WppTypography className={styles.qnSaveText}>
                                                            saved
                                                          </WppTypography>
                                                        </div>
                                                      )}
                                                    {multiSelectSuccess &&
                                                      saveDynamicDpmMultiSelectObjParser.id === que.question.id && (
                                                        <div className={styles.qnSaved}>
                                                          <WppIconAvailableCheckmark
                                                            size="m"
                                                            color="#2e7d32"
                                                            className={styles.qnSavedIcon}
                                                          />
                                                          &nbsp;
                                                          <WppTypography className={styles.qnSaveText}>
                                                            saved
                                                          </WppTypography>
                                                        </div>
                                                      )}
                                                    {textInvSuccess &&
                                                      saveDynamicDpmTextInventoryObjParser.id === que.question.id && (
                                                        <div className={styles.qnSaved}>
                                                          <WppIconAvailableCheckmark
                                                            size="m"
                                                            color="#2e7d32"
                                                            className={styles.qnSavedIcon}
                                                          />
                                                          &nbsp;
                                                          <WppTypography className={styles.qnSaveText}>
                                                            saved
                                                          </WppTypography>
                                                        </div>
                                                      )}

                                                    <WppActionButton
                                                      className={styles.accordBtn}
                                                      variant="secondary"
                                                      slot="actions"
                                                      key={`que-pm-marker-btn-queId-${que.question.id}`}
                                                      onClick={e =>
                                                        handlePmQnEditMemoised(
                                                          `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`,
                                                          e
                                                        )
                                                      }
                                                    >
                                                      {!showPmQnEdit && !dpmQnRetrievingSpinner ? (
                                                        <WppTooltip
                                                          text="Edit question"
                                                          key={`que-header-tooltip-edit-queId-${que.question.id}`}
                                                        >
                                                          <span
                                                            key={`que-header-edit-span-${que.question.id}`}
                                                            className="edit-area"
                                                            onClick={e =>
                                                              handlePmQnEditMemoised(
                                                                `${assessment.assessmentId}and${que.question.id}and${category.categoryId}`,
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <EditIcon color="success" fontSize="large" />
                                                          </span>
                                                        </WppTooltip>
                                                      ) : dpmQnIdx ===
                                                          `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}` &&
                                                        !dpmQnRetrievingSpinner ? (
                                                        <WppIconClose
                                                          slot="icon-start"
                                                          className={styles.closeGroupIcon}
                                                          size="s"
                                                          key={`que-pm-marker-close-icon-queId-${que.question.id}`}
                                                          onClick={e =>
                                                            handlePmQnEditMemoised(
                                                              `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`,
                                                              e
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <>
                                                          {!dpmQnRetrievingSpinner && (
                                                            <WppTooltip
                                                              text="Edit question"
                                                              key={`que-header-tooltip-edit-queId-${que.question.id}`}
                                                            >
                                                              <span
                                                                key={`que-header-edit-span-${que.question.id}`}
                                                                className="edit-area"
                                                                data-icon="edit-span"
                                                                onClick={e =>
                                                                  handleDpmQn(
                                                                    `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`,
                                                                    e
                                                                  )
                                                                }
                                                              >
                                                                <EditIcon
                                                                  data-icon="feedback"
                                                                  color="success"
                                                                  fontSize="large"
                                                                />
                                                              </span>
                                                            </WppTooltip>
                                                          )}
                                                        </>
                                                      )}
                                                    </WppActionButton>
                                                  </div>
                                                )}
                                                {que.response?.responseValue &&
                                                  category.categoryName !== ASSESSMENT.DATA_PRIVACY_MANAGER && (
                                                    <div
                                                      className={styles.qnReviewMarker}
                                                      key={`que-review-indicator-container-queId-${que.question.id}`}
                                                    >
                                                      {userRole.includes(GROUPS.DPM) &&
                                                        que.riskDetails &&
                                                        assessRowDetails.status !== STATUS.RESPONSE_PENDING && (
                                                          <div>
                                                            <WppActionButton
                                                              className={styles.accordBtn}
                                                              variant="secondary"
                                                              slot="actions"
                                                              key={`que-review-marker-btn-queId-${que.question.id}`}
                                                              onClick={() =>
                                                                selectedQuestionFn(que, assessment, category.categoryId)
                                                              }
                                                            >
                                                              <WppTooltip
                                                                text={`Risk: ${
                                                                  que.riskDetails.riskLevel
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()[0]
                                                                    .toUpperCase() +
                                                                  que.riskDetails.riskLevel
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .slice(1)
                                                                }`}
                                                                key={`que-header-risky-tooltip-queId-${que.question.id}`}
                                                              >
                                                                <span>
                                                                  {riskFlagIndicator(
                                                                    que.riskDetails?.riskLevel,
                                                                    que?.riskDetails?.status
                                                                  )}
                                                                </span>
                                                              </WppTooltip>
                                                            </WppActionButton>
                                                          </div>
                                                        )}
                                                      {userRole.includes(GROUPS.DPM) && (<WppActionButton
                                                        className={styles.accordBtn}
                                                        variant="secondary"
                                                        slot="actions"
                                                        key={`que-review-marker-btn-queId-${que.question.id}`}
                                                        onClick={e =>
                                                          handleReviewMemoised(
                                                            `${category.categoryId}and${que.question.id}`,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {!showReview ? (
                                                          <WppTooltip
                                                            text="Provide feedback"
                                                            key={`que-header-comment-tooltip-queId-${que.question.id}`}
                                                          >
                                                            <span
                                                              key={`que-header-comment-span-${que.question.id}`}
                                                              className="feedback-area"
                                                            >
                                                              <StyledBadge
                                                                badgeContent={que?.totalComments || 0}
                                                                color="info"
                                                              >
                                                                <FeedbackIcon color="success" fontSize="medium" />
                                                              </StyledBadge>
                                                            </span>
                                                          </WppTooltip>
                                                        ) : showCommentIdx ===
                                                          `${category.categoryId}and${que.question.id}` ? (
                                                          <span className={styles.closeWppGroup}>
                                                            <WppIconClose
                                                              width={16}
                                                              height={16}
                                                              slot="icon-start"
                                                              className={styles.closeGroupIcon}
                                                              size="s"
                                                              key={`que-review-marker-close-icon-queId-${que.question.id}`}
                                                              onClick={e =>
                                                                handleReviewMemoised(
                                                                  `${category.categoryId}and${que.question.id}`,
                                                                  e
                                                                )
                                                              }
                                                            />
                                                          </span>
                                                        ) : (
                                                          <WppTooltip
                                                            text="Provide feedback"
                                                            key={`que-review-marker-comment-icon-queId-${que.question.id}`}
                                                          >
                                                            <span
                                                              key={`que-header-comment-span-${que.question.id}`}
                                                              className="feedback-area"
                                                              data-icon="feedback-span"
                                                              onClick={e =>
                                                                handleReview(
                                                                  `${category.categoryId}and${que.question.id}`,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              <StyledBadge
                                                                badgeContent={que?.totalComments || 0}
                                                                color="info"
                                                              >
                                                                <FeedbackIcon
                                                                  data-icon="feedback"
                                                                  color="success"
                                                                  fontSize="medium"
                                                                />
                                                              </StyledBadge>
                                                            </span>
                                                          </WppTooltip>
                                                        )}
                                                      </WppActionButton>
                                                      )}
                                                    </div>
                                                  )}
                                              </div>
                                              <p
                                                key={`res-header-queId-${que?.question?.id}`}
                                                className={styles.queTag}
                                              >
                                                Response:
                                              </p>
                                              <div
                                                className={styles.response}
                                                key={`res-body-queId-${que?.question?.id}`}
                                              >
                                                {renderResponse(
                                                  que.question,
                                                  que?.attachments,
                                                  `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`,
                                                  que.response?.responseValue,
                                                )}

                                                {showPmQnEdit &&
                                                  category.categoryName === ASSESSMENT.DATA_PRIVACY_MANAGER &&
                                                  dpmQnIdx ===
                                                    `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}` && (
                                                    <Save
                                                      ref={dpmSaveRef}
                                                      dpmQn={que.question}
                                                      savedResponse={savedResponse}
                                                      dpmApiResponse={que.response?.responseValue}
                                                      inlineEdit={showPmQnEdit}
                                                      executeSingleSelectSave={
                                                        pdmResponseHolder.singleSelectHandler.saveSingleSelect
                                                      }
                                                      tempQnBank={tempQnBank}
                                                      executeMultiSelectSave={
                                                        pdmResponseHolder.multiSelectHandler.saveMultiSelect
                                                      }
                                                      executeTextInventorySave={
                                                        pdmResponseHolder.textInventoryHandler.saveTextInventory
                                                      }
                                                      dpmQnRetrievingSpinner={dpmQnRetrievingSpinner}
                                                      dpmQnSaveSpinner={dpmQnSaveSpinner}
                                                      dpmSaveKey={`${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`}
                                                    />
                                                  )}
                                                {(singleSelectFailure &&
                                                  saveDynamicDpmSingleSelectObjParser.id === que.question.id) ||
                                                  (dpmQnRetrievedError && (
                                                    <WppBanner
                                                      key={`dpm-banner-${que.question.id}`}
                                                      id="dpm-banner"
                                                      type="information"
                                                      show={singleSelectFailure}
                                                      className={styles.dpmBanner}
                                                    >
                                                      Unable to save!. Please refresh or try later.
                                                    </WppBanner>
                                                  ))}
                                                {(multiSelectFailure &&
                                                  saveDynamicDpmMultiSelectObjParser.id === que.question.id) ||
                                                  (dpmQnRetrievedError && (
                                                    <WppBanner
                                                      key={`dpm-banner-${que.question.id}`}
                                                      id="dpm-banner"
                                                      type="information"
                                                      show={multiSelectFailure}
                                                      className={styles.dpmBanner}
                                                    >
                                                      Unable to save!. Please refresh or try later.
                                                    </WppBanner>
                                                  ))}
                                                {(textInvFailure &&
                                                  saveDynamicDpmTextInventoryObjParser.id === que.question.id) ||
                                                  (dpmQnRetrievedError && (
                                                    <WppBanner
                                                      key={`dpm-banner-${que.question.id}`}
                                                      id="dpm-banner"
                                                      type="information"
                                                      show={textInvFailure}
                                                      className={styles.dpmBanner}
                                                    >
                                                      Unable to save!. Please refresh or try later.
                                                    </WppBanner>
                                                  ))}
                                              </div>

                                              {showReview &&
                                              showCommentIdx === `${category.categoryId}and${que.question.id}` ? (
                                                <div
                                                  key={`review-container-catId-${category.categoryId}-queId-${que.question.id}`}
                                                >
                                                  <div
                                                    className={styles.reviewContainer}
                                                    key={`review-inner-container-catId-${category.categoryId}-queId-${que.question.id}`}
                                                  >
                                                      <div className={styles.actionStatusContainer}>
                                                        <div className={styles.firstRow}>
                                                          {(showBanner &&
                                                            saveDynamicObjParser.questionId === que.question.id) ||
                                                            (dpmQnRetrievedError && (
                                                              <WppBanner
                                                                key={`banner-${que.question.id}`}
                                                                id="banner"
                                                                type="information"
                                                                show={showBanner}
                                                                className={styles.banner}
                                                              >
                                                                Unable to save!. Please refresh or try later.
                                                              </WppBanner>
                                                            ))}
                                                          <div className={styles.commentContainer}>
                                                            <CommentSection
                                                              commentKey={`${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response.id}`}
                                                              ref={commentRef}
                                                              apiCommentHistory={que.comments?.length && que.comments}
                                                              comment={commentGetter.comment}
                                                              executeComment={receiveComment}
                                                            />
                                                            <CommentHistory
                                                              cmtHistoryKey={`${assessment.assessmentId}and${que?.question?.id}and${category.categoryId}and${que?.response?.id}`}
                                                              getComments={getComments.value}
                                                              serviceURL={serviceURL}
                                                              initialComments={que.comments?.length && que.comments}
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className={styles.secondRow}>
                                                          <SaveSection
                                                            ref={saveRef}
                                                            commentRefs={commentGetter}
                                                            executeSave={saveQuestion}
                                                            flagQue={que?.response?.risky}
                                                            categoryID={category?.categoryId}
                                                            saveKey={`${assessment.assessmentId}and${que?.question?.id}and${category.categoryId}and${que?.response?.id}`}
                                                          />
                                                          <div />
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    })}
                                  </div>
                                </div>
                              </WppAccordion>
                            )}
                            {isEligibleForReview() &&
                              category?.categoryName === ASSESSMENT.DATA_PRIVACY_MANAGER &&
                              assessRowDetails.status === REVIEW_STATUS.REVIEW_PENDING && (
                                  <WppAccordion
                                    size="m"
                                    withDivider={false}
                                    key={`cat-parent-accordian-container-catId-${category.categoryId}`}
                                    className={styles.accordStyle}
                                    onWppChange={e => accordionHandler(e, category.categoryName)}
                                  >
                                    <WppTypography
                                      type="m-strong"
                                      slot="header"
                                      className={styles.accordHeader}
                                      key={`cat-parent-accordian-header-catId-${category.categoryId}`}
                                    >
                                      {category.categoryName} (only for privacy manager)
                                    </WppTypography>

                                    <Review
                                      dpmReviewComment={dpmReviewComment}
                                      assessment={assessment}
                                      setDpmReviewComment={setDpmReviewComment}
                                      selectReviewOutcome={selectReviewOutcome}
                                      setSelectReviewOutcome={setSelectReviewOutcome}
                                      showReviewOutcomeSaveSpinnner={showReviewOutcomeSaveSpinnner}
                                      setShowReviewOutcomeSaveSpinnner={setShowReviewOutcomeSaveSpinnner}
                                      showOutcomeBanner={showOutcomeBanner}
                                      setShowOutcomeBanner={setShowOutcomeBanner}
                                      showApproveToast={showApproveToast}
                                      closeSideModal={closeSideModal}
                                      riskStatusOpen={riskStatusOpen}
                                    />
                                  </WppAccordion>
                              )}
                          </>
                        )
                      })}
                  </>
                ) : (
                  <div className={styles.assessmentNotFound}>No Response Found</div>
                )}
              </>
            ),
            quickSummary: (
              <>
                {assessment.isAllQnNonEmpty ? (
                  <>
                    {loadQueAndAns &&
                      prepareQuickSummaryData(assessment.details).map((category: any) => {
                        return (
                          <>
                            {category?.categoryName !== ASSESSMENT.DATA_PRIVACY_MANAGER && (
                              <WppAccordion
                                size="m"
                                withDivider={false}
                                key={`cat-parent-accordian-container-catId-${category.categoryId}`}
                                className={
                                  category.categoryName !== ASSESSMENT.DATA_PRIVACY_MANAGER
                                    ? styles.accordStyle
                                    : ''
                                }
                                onWppChange={e => accordionHandler(e, category.categoryName)}
                              >
                                <WppTypography
                                  type="m-strong"
                                  slot="header"
                                  className={styles.accordHeader}
                                  key={`cat-parent-accordian-header-catId-${category.categoryId}`}
                                >
                                  {category.categoryName} ({category.questionAnswers.length})
                                </WppTypography>

                                <div className={styles.assessAcordianBody}>
                                  <div
                                    key={`cat-parent-accordian-body-catId-${category.categoryId}`}
                                    className={styles.catDescription}
                                  >
                                    {category?.questionAnswers?.map((que: any) => {
                                      return (
                                        <>
                                          {Object.keys(que).length ? (
                                            <div
                                              key={`que-container-queId-${que.question.id}`}
                                              className={styles.questionDetails}
                                            >
                                              <div
                                                key={`que-container-header-queId-${que.question.id}`}
                                                className={styles.qnHeaderContainer}
                                              >
                                                <p key={`que-header-tag-container-queId-${que.question.id}`}>
                                                  <span
                                                    className={styles.queTag}
                                                    key={`que-header-span-queId-${que.question.id}`}
                                                  >
                                                    {que.question.sequence} :{' '}
                                                  </span>
                                                  {que.question.description + ' '}
                                                  {que.question.subDescription?.trim().length > 0 && (
                                                    <WppTooltip
                                                      header="Description"
                                                      text={que.question.subDescription}
                                                      key={`que-header-tooltip-queId-${que.question.id}`}
                                                    >
                                                      <WppIconInfo
                                                        key={`que-header-tooltip-icon-queId-${que.question.id}`}
                                                      />
                                                    </WppTooltip>
                                                  )}
                                                </p>
                                              </div>
                                              <p
                                                key={`res-header-queId-${que?.question?.id}`}
                                                className={styles.queTag}
                                              >
                                                Response:
                                              </p>
                                              <div
                                                className={styles.response}
                                                key={`res-body-queId-${que?.question?.id}`}
                                              >
                                                {renderResponse(
                                                  que.question,
                                                  que?.attachments,
                                                  `${assessment.assessmentId}and${que.question.id}and${category.categoryId}and${que.response?.id}`,
                                                  que.response?.responseValue,
                                                )}
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    })}
                                  </div>
                                </div>
                              </WppAccordion>
                            )}
                          </>
                        )
                      })}
                  </>
                ) : (
                  <div className={styles.assessmentNotFound}>No Response Found</div>
                )}
              </>
            )
          }[currentTab]
        }
      </>
    )
  }
)
export default connect(mapStateToProps, mapDispatchToProps)(QuesAnsAssessment)